@import "../assets/styles/variables.scss";

.quantity-group {
    margin-bottom: 20px;

    div {
        width: 100%;

        .form-label {
            @include form-label;
            margin-bottom: 6px;

            span {
                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            @include form-input();
            border-radius: none;
            text-align: center;

            &:focus,
            &:active {
                border: 1px solid $primary-color;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($black, $alpha: 0.5);
            }
        }

        .form-text {
            &.error {
                color: $error-red;
            }
        }
    }

    button {
        width: 80px;
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid $input-border;

        svg {
            stroke: $black;
        }

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:hover,
        &:active,
        &:focus {
            svg {
                stroke: $primary-color;
            }
        }
    }
}
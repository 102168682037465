@import "../assets/styles/variables.scss";

.search-group {
    .filter {
        &__control {
            height: 48px;
            width: 203px;
            @include form-input();
            border-radius: 25px 0 0 25px;
            border-right: 0;
            box-shadow: none;

            &:hover, &:focus {
                border-color: $primary-color;
            }

            @media (max-width:992px) {
                width: 130px;
            }
        }



        &__indicator-separator {
            display: none;
        }

        &__value-container {
            padding-left: 20px;
        }

        &__menu {
            margin: 1px 0;
            border-radius: 10px;
            padding: 7px 0;
        }

        &__option {
            color: $black;

            &:hover {
                cursor: pointer;
                background: $bg-color;
            }
        }
    }

    .form-control {
        height: 50px;
        @include form-input();
        border-radius: 0 25px 25px 0 !important;
        padding-right: 80px;

        &:focus,
        &:active {
            border: 1px solid $input-border;
        }
    }

    .clear-search {
        background: none;
        border: none;
        right: 50px;
        top: 11px;

        svg {
            fill: $readonly;

            &:hover,
            &:focus,
            &:active {
                fill: $primary-color;
            }
        }
    }

    .search {
        background: $primary-color;
        border: none;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        right: 5px;
        top: 4px;

        svg {
            stroke: $white;
        }

        &:active {
            background: $primary-color-hover !important;
            border-color: $primary-color-hover;
            box-shadow: inset 0 5px 11px 0 rgba(130, 194, 3, .5),
            0 4px 15px 0 rgba(130, 194, 3, .5) !important;
        }

        &:hover,
        &:focus {
            background: $primary-color;
        }

        &:focus-visible {
            box-shadow: none;
        }
    }

    @media(max-width: 767px) {
        margin-bottom: 15px;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}
@import "../assets/styles/variables.scss";

.schedules-outer {
    background: $white;
    padding: 30px;
    margin-top: 24px !important;

    &:first-child {
        margin-top: 40px !important
    }

    @include prdt-radius();

    .img-view {
        &.single {
            .prod-img-single {
                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                    @include b-radius;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .prod-img {
            img {
                width: calc(100% / 2);
                object-fit: cover;
                height: 90px;
                @include b-radius;

                @media (max-width:992px) {
                    height: 160px;
                }

                @media (max-width:576px) {
                    height: 90px;
                }

                &:first-child {
                    margin-right: 4px;
                }

                &:last-child {
                    margin-left: 4px;
                }

                &.mb-8 {
                    margin-bottom: 8px;
                }
            }

            .count {
                width: calc(100% / 2);
                background-color: rgba($black, $alpha: .8);
                @include b-radius;
                font-size: $head1-fs;
                font-weight: $medium;
                color: $white;
                height: 90px;
                margin-left: 4px;

                @media (max-width:992px) {
                    height: 160px;
                }

                @media (max-width:576px) {
                    height: 90px;
                }
            }
        }
    }

    .schedule-view {
        .schedule-view-inner {
            padding: 0 20px;

            @media (max-width:992px) {
                padding: 20px 0 0 0;
            }

            h3 {
                font-size: $top-nav-fs;
                font-weight: $bold;
                margin-bottom: 20px;
            }

            .days-group {
                margin-bottom: 25px;

                .btn {
                    width: 60px !important;
                    height: 48px !important;
                    box-shadow: none;
                    border: 1px solid $bg-color !important;
                    font-size: $primary-fs;
                }

                .btn-check:checked+.btn {
                    border: none !important;
                }
            }

            .date-picker {
                margin-bottom: 10px;

                @media (max-width:576px) {
                    margin-bottom: 20px;
                }
            }
            p{
                &.schedule-info{
                    background: url(../assets/img/schedule-info.svg) 0 0 no-repeat;
                    padding: 1px 0 2px 30px;
                    margin: 15px 0 0;
                }
            }
        }   
    }

    .action-btn {
        @media (max-width:992px) {
            padding-top: 30px;
        }

        .btn-primary {
            margin: 40px 0 20px;

            @media (max-width:992px) {
                margin: 0 20px 0 0;
            }
        }

        .btn {
            @media (max-width:576px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
@import "../assets/styles/variables.scss";

.card-details{
    label{
        @include form-label;
        margin-bottom: 6px;
    }
    .card-number {
        border: 1px solid $input-border;
        font-family: $secondary-font;
        font-size: $form-input-fs;
        color: $black;
        @include input-radius();
        padding: 15px .75rem;
        &:focus,
        &:active {
            border: 1px solid $primary-color;
        }
      }  
      .form-text {
        &.error {
            color: $error-red;
        }
    } 
}

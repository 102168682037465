@import "../assets/styles/variables.scss";

.forgot-pass-wrap {
    width: 520px;
    background: $white;
    @include b-radius;
    padding: 80px;
    margin: 50px auto 0;

    @media (max-width:576px) {
        padding: 30px;
        width: 100%;
    }

    h1 {
        margin-bottom: 20px !important;

        @media (max-width:576px) {
            margin-bottom: 20px;
        }
        &.reset-head{
            margin-bottom: 40px!important;
        }
    }

    p {
        line-height: 24px;

        &.info {
            margin-bottom: 30px;
        }

        &.pass-alert {
            color: $primary-color;
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}
.view-password {
    right: 22px;
    top: 41px;
    cursor: pointer;
}

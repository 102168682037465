@import "../assets/styles/variables.scss";

.cut-select-label{
    margin-bottom: 20px;
    .form-label {
        @include form-label;
        margin-bottom: 6px;
        span{
            font-size: $link-fs;
            padding-left: 4px;
        }
    }
    .filter {
        &__control {
            height: 48px;
            width: 100%;            
            @include form-input();                        
            box-shadow: none;

            &:hover {
                border-color: $input-border;
            }
        }

        &__control--is-focused {
            border-color: $primary-color;
        }

        &__indicator-separator {
            display: none;
        }

        &__value-container {
            padding-left: .375rem;
        }

        &__menu {
            margin: 1px 0;
            border-radius: 10px;
            padding: 7px 0;
        }

        &__option {            
            color: $black;

            &:hover {
                background: $bg-color;
            }
        }
    }
}
.select-child{
padding-right: 3px;
    input{
    border: $input-border solid 1px;
    height: 48px;
    border-left: 0 !important;
    margin-top: 26px !important;
    display: flex;
    align-items: center;
    border-radius: 0 4px 4px 0 !important;
}
}
.border-right-radius-none{
    .filter__control{
    border-radius: 4px 0 0 4px;
}
}
@import "../assets/styles/variables.scss";

.input-select {
    margin-bottom: 20px;

    .form-label {
        @include form-label;
        margin-bottom: 6px;

        span {
            font-size: $link-fs;
            padding-left: 4px;
        }
    }

    .group-wrap {

        .form-control {
            @include form-input();
            border-right: none !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: calc(100% - 104px);

            &:focus,
            &:active {
                border: 1px solid $primary-color;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($black, $alpha: 0.5);
            }
        }

        .form-text {
            &.error {
                color: $error-red;
            }
        }

        .cust-options {
            &__control {
                height: 48px;
                width: 104px;
                @include form-input();
                box-shadow: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &:hover {
                    border-color: $input-border;
                }
            }

            &__control--is-focused {
                border-color: $primary-color;
            }

            &__indicator-separator {
                display: none;
            }

            &__value-container {
                padding-left: .375rem;
            }

            &__menu {
                margin: 1px 0;
                border-radius: 10px;
                padding: 7px 0;
            }

            &__option {
                color: $black;

                &:hover {
                    background: $bg-color;
                }
            }
        }
    }
}

// .select-child {
//     padding-right: 3px;

//     input {
//         border: $input-border solid 1px;
//         height: 48px;
//         border-left: 0 !important;
//         margin-top: 26px !important;
//         display: flex;
//         align-items: center;
//         border-radius: 0 4px 4px 0 !important;
//     }
// }

// .border-right-radius-none {
//     .filter__control {
//         border-radius: 4px 0 0 4px;
//     }
// }
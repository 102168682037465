@import "../assets/styles/variables.scss";

.location-zipcode {
    padding: 20px;
    border-bottom: 1px solid #DBDBDB;

    &:last-child {
        border-bottom: 0;
    }

    span {
        font-weight: $semi-bold;
        margin-bottom: 5px;
    }
}

.hover-cursor-pointer {
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        fill: $block;
    }
}

@import "./variables.scss";

.login{
    .container{
        @media (min-width:1200px){
            width: 1050px;
        }
    }
    .login-outer{
        min-height: 100vh;    
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width:576px){
            padding-top: 20px;
            padding-bottom: 20px;

        }
        .login-left{
            .login-img{
                @media (max-width:992px){
                    margin-bottom: 20px;
                }
            }
            .logo{
                margin-bottom: 80px;
                @media (max-width:992px){
                    margin-bottom: 40px;;
                }
            }
            p{
                font-size: 16px;
                font-weight: $semi-bold;
                padding-top: 25px;
                max-width: 430px;                
                @media (max-width:992px){
                    padding-top: 0;
                    padding-bottom: 20px;
                }
            }
        }
        .login-wrap{           
            background: $white;
            @include b-radius;
            padding: 80px;
            @media (max-width:576px){
                padding: 30px;
            }
            h1{
                margin-bottom: 50px;
                @media (max-width:576px){
                    margin-bottom: 20px;
                }
            }
            .forgot-pass{
             margin-bottom: 20px;
            }
            .reg-info{            
                line-height: 24px;
                margin-top: 30px;        
                a{
                    font-weight: $semi-bold;       
                }
            }
        }
    }
}

@import "../assets/styles/variables.scss";

.register-wrap {
    background: $white;
    @include b-radius;
    margin-top: 40px;
    max-width: 855px;

.stepper-wrap {
    border-bottom: 1px solid $table-bd;
    padding: 40px;
}

.form-wrap {
    padding: 40px;

    .btn-wrap {
        padding-top: 20px;

        .login-info {
            a {
                font-weight: $semi-bold;
            }
        }

        .btn {
            &:first-child {
                margin-right: 20px;

                @media (max-width:576px) {
                    margin-bottom: 20px;
                }
            }

            @media (max-width:576px) {
                width: 100%;
            }
        }
    }

    .agree {
        padding-top: 20px;
        padding-bottom: 20px;

        span {
            padding-left: 10px;

            &.error {
                color: $error-red;
                font-size: .875em;
                padding-left: 0;
                margin-top: 10px;
            }
        }

        a {
            font-weight: $semi-bold;
        }
    }

    p {
        line-height: 24px;
        padding-bottom: 30px;

        span {
            font-weight: $semi-bold;
        }
    }

    .address-clear {
        right: 20px;
        top: 40px;
    }
}

&.success {
    padding: 50px;
    max-width: 750px;

    h1 {
        font-weight: $bold;
        font-size: 24px;
        color: $primary-color;
    }

    p {
        padding: 35px 0;
        font-size: 16px;
        line-height: 26px;
        max-width: 540px;

        span {
            font-weight: $bold;
            color: $primary-color;
        }
    }

    img {
        top: 0;
        bottom: 0;
        right: -210px;
        margin: auto;

        @media (max-width:992px) {
            display: none;
        }

        @media (max-width:1200px) {
            right: 10px;
        }
    }
}

&.success-heatmap {
    padding: 0px;
    max-width: inherit;

    h1 {
        font-weight: $bold;
        font-size: 24px;
        color: $primary-color;
    }

    p {
        padding: 35px 0;
        font-size: 16px;
        line-height: 26px;
        max-width: 540px;

        span {
            font-weight: $bold;
            color: $primary-color;
        }
    }

}

&.reset-success {
    min-height: 350px;
}
}
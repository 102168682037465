@import "../assets/styles/variables.scss";

.textarea-group {
    margin-bottom: 20px;

    div {
        width: 100%;

        .form-label {
            @include form-label;
            margin-bottom: 6px;
            display: block;

            span {
                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            @include form-input();
            min-height: 160px;
            resize: none;

            &:focus,
            &:active {
                border: 1px solid $primary-color;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($black, $alpha: 0.5);
            }
        }

        .form-text {
            &.error {
                color: $error-red;
            }
        }
    }
}
@import "../assets/styles/variables.scss";

.product-wrap {
    margin-top: 24px !important;

    .product {
        background: $white;
        @include prdt-radius;
        padding: 20px;
        border: 1px solid $white;
        transition: 0.5s ease;

        .nation-wide {
            border-radius: 6px;
            background: $secondary-color;
            left: 20px;
            top: 20px;
            padding: 5px 10px;
            font-size: 11px;
            font-weight: $bold;
            z-index: 10;
        }

        .expired-badge {
            border-radius: 6px;
            background: $error-red;
            right: 20px;
            top: 20px;
            color: $white;
            padding: 5px 10px;
            font-size: 11px;
            font-weight: $bold;
            z-index: 10;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0);
            transition: 0.7s ease;
            z-index: 20;
            @include prdt-radius;
        }

        &:hover {
            border: 1px solid $primary-color;

            .overlay {
                display: block;
                background: rgba(255, 255, 255, .8);
            }

            .img-wrap {
                .btn-wrap {
                    a {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .img-wrap {
            height: 155px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            img {
                height: 155px;
                width: 100%;
                object-fit: cover;
                border-radius: 6px;
            }

            .btn-wrap {
                z-index: 999;

                a {
                    width: 46px;
                    height: 46px;
                    display: inline-block;
                    border-radius: 23px;
                    background: $primary-color;
                    border: none;
                    opacity: 0;
                    transition: 0.5s ease;
                    margin: 0 10px;
                    text-align: center;
                    padding-top: 10px;

                    svg {
                        stroke: $white;
                    }

                    &:active {
                        background: $primary-color-hover;
                        border-color: $primary-color-hover;
                        box-shadow: inset 0 5px 11px 0 rgba(29, 160, 246, .5),
                            0 4px 15px 0 rgba(26, 160, 246, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }


        }

        span {
            &.title {
                font-size: $prodt-title-fs;
                font-weight: $bold;
                padding: 15px 0;
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &.price {
                background: $primary-color;
                font-size: $head1-fs;
                color: $white;
                font-weight: $semi-bold;
                @include b-radius;
                padding: 5px;
                min-width: 112px;
            }

            &.qty {
                background: $input-border;
                @include input-radius;
                padding: 5px 10px;
                font-weight: $bold;
            }
        }

        .check-box {
            right: 15px;
            top: 15px;
            z-index: 30;
        }

        &.expired {
            .img-wrap {
                img {
                    filter: grayscale(1);
                }
            }

            span {
                &.price {
                    background: $input-border;
                }
            }
            &.no-qty{
                span{
                    &.qty{
                        background: $error-red;
                        color: $white;
                    }
                }
            }
        }
    }
}
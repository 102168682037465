@import "../assets/styles/variables.scss";

.deals-wrap {
    margin-top: 24px !important;

    .deals {
        background: $white;
        @include prdt-radius;
        padding: 20px;
        border: 1px solid $white;

        .img-wrap {
            height: 155px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            img {
                height: 155px;
                width: 100%;
                object-fit: cover;
                border-radius: 6px;
            }
        }

        span {
            &.title {
                font-size: $prodt-title-fs;
                font-weight: $bold;
                padding: 15px 0;
            }

            &.price {
                background: $primary-color;
                font-size: $head1-fs;
                color: $white;
                font-weight: $semi-bold;
                @include b-radius;
                padding: 5px;
                min-width: 112px;
            }

        }

    }
}
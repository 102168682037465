@import "../assets/styles/variables.scss";

.cd-loader {
  background: rgba($color: $black, $alpha: 0.6);
  opacity: 1 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;

  .loader {
    position: relative;
    display: flex;
  }

  .loader:before,
  .loader:after {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin: 0 5px;
    border-radius: 50%;
    color: $secondary-color;
    background: currentColor;
    box-shadow: 50px 0, -50px 0;
    animation: left 1s infinite ease-in-out;
  }

  .loader:after {
    color: $primary-color;
    animation: right 1.1s infinite ease-in-out;
  }

  @keyframes right {
    0%,
    100% {
      transform: translateY(-10px);
    }

    50% {
      transform: translateY(10px);
    }
  }

  @keyframes left {
    0%,
    100% {
      transform: translateY(10px);
    }

    50% {
      transform: translateY(-10px);
    }
  }
}

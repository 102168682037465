@import "../assets/styles/variables.scss";


.upload-wrap {    
    flex-wrap: wrap;    
    .custom-file-upload {        
        width: 168px;
        height: 120px;
        border: 1px solid $primary-color;
        cursor: pointer;
        border-radius: 16px;        
        margin-bottom: 15px;
        input[type="file"] {
            opacity: 0;
            width: 168px;
            height: 120px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 999;
            cursor: pointer;
        }

        svg {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 100;
        }
    }
    .image-view{                
        width: 168px;
        height: 120px;        
        border-radius: 16px;        
        margin-left: 15px;      
        margin-bottom: 15px;
        border: 1px solid $bg-color;
        @media (max-width:576px){
            margin-left: 0;
        }
        &:hover{
            .delete{
                opacity: 1;
                cursor: pointer;
            }
        }
        img{
            width: 100%;            
            height: 100%;
            border-radius: 16px;
            object-fit: cover;

        }
        .delete{
            background: $primary-color;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            justify-content: center;
            align-items: center;
            right: 10px;
            top: 10px;
            opacity: 0;
            transition: 0.5s ease;            
            svg{
                stroke: $white;
                width: 18px;
            }
            &:hover{
                background: $primary-color-hover;
            }
        }
        .default-image{
            bottom: -35px;
            left: 0;
            .form-check-label{
                margin-left: 8px;
            }
        }
        .check-wrap{
            top: 10px;
            left: 10px;
        }

    }
}
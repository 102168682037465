@import "../assets/styles/variables.scss";

.plan-wrap {
    @media (max-width:1200px) {
        margin-bottom: 20px;
    }

    .plan-outer {
        padding: 10px 2px;
        @include modal-radius;

        .plan-header {
            font-size: 22px;
            font-weight: bold;
            padding: 10px 0 15px 0;
        }

        .plan-dtl-outer {
            background: $white;
            padding: 30px 0;
            @include modal-radius;
        }

        .plan-price {
            text-align: center;
            padding: 0 30px 30px;

            h4 {
                font-size: 45px;
                font-weight: $extra-bold;
                padding-bottom: 15px;

                span {
                    font-size: 16px;
                    font-weight: $bold;
                    padding-left: 10px;
                }
            }

            button {
                min-width: 133px;
                height: 53px;
                font-size: 16px;
                font-weight: $semi-bold;
            }
        }

        .plan-body {
            ul {
                margin: 0;
                padding: 0;

                li {
                    color: $black;
                    margin-bottom: 18px;
                    list-style: none;
                    padding-left: 30px;

                    &.plan-inactive {
                        background: url(../assets/img/tier-close.svg)0 7px no-repeat;
                    }
                }
            }

            .top {
                padding: 25px 30px;

                ul {
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .bottom {
                padding: 25px 30px 0;

                ul {
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &.tier1 {
            background: $bg-color;

            ul {
                li {
                    background: url(../assets/img/tier1-li-bg.svg)0 7px no-repeat;
                }
            }

            .plan-body {
                .top {
                    background: $bg-color;
                }
            }

            .plan-price {
                button {
                    background: $black;
                    border-color: $black;

                    &:active {
                        background: #545454;
                        border-color: #545454;
                        box-shadow: inset 0 5px 11px 0 rgba(122, 121, 121, .5),
                            0 4px 15px 0 rgba(122, 121, 121, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

            &.active {
                box-shadow: 0 0 18px rgba(0, 0, 0, .2);
            }
        }

        &.tier2 {
            background: $primary-color;

            .plan-header {
                color: $white;
            }

            ul {
                li {
                    background: url(../assets/img/tier2-li-bg.svg)0 7px no-repeat;
                }
            }

            .plan-body {
                .top {
                    background: rgba(148, 218, 10, 0.1);
                }
            }

            .plan-price {
                button {
                    background: $primary-color;
                    border-color: $primary-color;

                    &:active {
                        background: $primary-color-hover;
                        border-color: $primary-color-hover;
                        box-shadow: inset 0 5px 11px 0 rgba(130, 194, 3, .5),
                            0 4px 15px 0 rgba(130, 194, 3, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

            &.active {
                box-shadow: 0 0 18px rgba(115, 168, 10, .5);
            }
        }

        &.tier3 {
            background: $secondary-color;

            ul {
                li {
                    background: url(../assets/img/tier3-li-bg.svg)0 7px no-repeat;
                }
            }

            .plan-body {
                .top {
                    background: rgba(255, 196, 6, 0.1);
                }
            }

            .plan-price {
                button {
                    background: $secondary-color;
                    border-color: $secondary-color;
                    color: $black;

                    &:active {
                        background: #FFD344;
                        border-color: #FFD344;
                        box-shadow: inset 0 5px 11px 0 rgba(254, 212, 76, .5),
                            0 4px 15px 0 rgba(254, 212, 76, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

            &.active {
                box-shadow: 0 0 18px rgba(255, 196, 6, .5);
            }
        }

        &.tier4 {
            background: $plan-blue;

            .plan-header {
                color: $white;
            }

            ul {
                li {
                    background: url(../assets/img/tier4-li-bg.svg)0 7px no-repeat;
                }
            }

            .plan-body {
                .top {
                    background: rgba($color: $plan-blue, $alpha: 0.1)
                }
            }

            .plan-price {
                button {
                    background: $plan-blue;
                    border-color: $plan-blue;
                    color: $white;

                    &:active {
                        background: #4BA2F8;
                        border-color: #4BA2F8;
                        box-shadow: inset 0 5px 11px 0 rgba(75, 162, 248, .5),
                            0 4px 15px 0 rgba(75, 162, 248, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

            &.active {
                box-shadow: 0 0 18px rgba(75, 162, 248, .5);
            }
        }
    }
}
//Fonts
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
$primary-font: "Plus Jakarta Sans", sans-serif;
$secondary-font: "Inter", sans-serif;

//Primary Colors
$primary-color:#94DA0A;
$secondary-color:#F9C901;
$primary-color-hover:#89CB08;
$black:#000000;
$white:#ffffff;
$bg-color:#F1F1F1;
$label-color:#5B5B5B;
$input-border:#9E9E9E;
$error-red:#F63434;
$action-bd-color:#c0c0c0;
$check-bd:#707070;
$thead-color:#E5E5E5;
$table-bd:#E6E9EC;
$pagination:#52575D;
$readonly:#9E9E9E;
$btn-sec:#C8C2BD;
$green:#00A706;
$block:#CB1C00;
$plan-blue:#1E90FF;

//Font Weights
$light: 300;
$regular: 400;
$medium:500;
$semi-bold: 600;
$bold: 700;
$extra-bold:800;

//Font Size
$primary-fs:14px;
$head1-fs:20px;
$head2-fs:25px;
$form-label-fs:13px;
$form-input-fs:15px;
$prodt-title-fs:17px;
$top-nav-fs:18px;
$link-fs:12px;
$btn-fs:17px;

//Mixins
@mixin b-radius() {
    border-radius: 8px;
}

@mixin input-radius() {
    border-radius: 4px;
}

@mixin btn-radius() {
    border-radius: 33px;
}

@mixin prdt-radius() {
    border-radius: 23px;
}

@mixin modal-radius() {
    border-radius: 22px;
}

@mixin head1() {
    font-size: $head1-fs;
    color: $black;
    font-weight: $bold;
    margin: 0;
}
@mixin head2() {
    font-size: $head2-fs;
    color: $black;
    font-weight: $bold;
    margin: 0;
}

@mixin form-label() {
    font-size: $form-label-fs;
    color: $label-color;
}

@mixin form-input() {
    height: 48px;
    border: 1px solid $input-border;
    font-family: $secondary-font;
    font-size: $form-input-fs;
    color: $black;
    @include input-radius();
}

@mixin btn-main() {
    font-size: $btn-fs;
    font-weight: $semi-bold;
    @include btn-radius;
    height: 60px;
    min-width: 140px;
    padding-left: 25px;
    padding-right: 25px;
}
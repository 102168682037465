@import "../assets/styles/variables.scss";

.terms-condition{
    word-wrap: break-word;
    h3{
        font-size: $prodt-title-fs;
        font-weight: $bold;
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 20px!important;
    }
    ul{
        li{
            list-style-type: square;
            margin-bottom: 15px;
            padding-left: 10px;
        }
    }
    ol{
        li{
            
            margin-bottom: 15px;
            padding-left: 10px;
        }
    }
    a{
        color: $black;
        font-weight: $bold;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
            color: $black;
        }
    }
}
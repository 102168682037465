@import "../assets/styles/variables.scss";

.plan-subscribed {
    h1 {
        font-size: 23px;
        font-weight: $bold;
    }

    h5 {
        font-size: $primary-fs;
        margin-top: 5px;
    }

    .pr-0 {
        padding-right: 0;
    }

    .plan-wrap {
        min-height: 260px;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 30px 0;

        .current-plan {
            min-height: 260px;
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .title {
                font-size: $top-nav-fs;
                color: rgba(0, 0, 0, 0.8);

                span {
                    font-weight: $bold;
                    color: $black;
                }
            }

            h4 {
                font-size: 52px;
                font-weight: $bold;
                padding: 10px 0 10px 0;

                span {
                    font-size: $top-nav-fs;
                    padding-left: 5px;
                }
            }

            button {
                min-width: 155px;
                height: 55px;
                font-size: $prodt-title-fs;
                font-weight: $semi-bold;
                background: $black;
                border-color: $black;

                &:active {
                    background: #545454;
                    border-color: #545454;
                    box-shadow: inset 0 5px 11px 0 rgba(122, 121, 121, .5),
                        0 4px 15px 0 rgba(122, 121, 121, .5) !important;
                }

                &:focus-visible {
                    box-shadow: none;
                }
            }
        }

        .plan-details {
            background: $white;
            min-height: 260px;
            border-radius: 12px;
            border: 1px solid #C7DAE7;
            padding: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .plan-cancel-wrap {
                .btn-link {
                    font-size: $primary-fs;
                    color: $black;

                    &:active {
                        text-decoration: none;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

            .plan-dtl-wrap {
                .plan-info {
                    padding: 30px 0 0;

                    label {
                        font-size: 16px;
                        padding-bottom: 10px;
                    }

                    span {
                        font-size: $top-nav-fs;
                        font-weight: $semi-bold;
                    }

                    div {
                        @media (max-width:576px) {
                            margin-bottom: 10px;
                        }
                    }
                }

                p {
                    border-top: 0;
                    padding-top: 30px;
                }
            }

        }

        &.tier1 {
            background: $bg-color;
        }

        &.tier2 {
            background: $primary-color;

            .current-plan {
                color: $white;

                .title {
                    color: rgba(255, 255, 255, 0.8);

                    span {
                        color: $white;
                    }
                }

                button {
                    background: $white;
                    border-color: $white;
                    color: $primary-color;

                    &:active {
                        background: #545454;
                        border-color: #545454;
                        color: $white;
                        box-shadow: inset 0 5px 11px 0 rgba(122, 121, 121, .5),
                            0 4px 15px 0 rgba(122, 121, 121, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }

        }

        &.tier3 {
            background: $secondary-color;
        }

        &.tier4 {
            background: $plan-blue;

            .current-plan {
                color: $white;

                .title {
                    color: rgba(255, 255, 255, 0.8);

                    span {
                        color: $white;
                    }
                }

                button {
                    background: $white;
                    border-color: $white;
                    color: $plan-blue;

                    &:active {
                        background: #545454;
                        border-color: #545454;
                        color: $white;
                        box-shadow: inset 0 5px 11px 0 rgba(122, 121, 121, .5),
                            0 4px 15px 0 rgba(122, 121, 121, .5) !important;
                    }

                    &:focus-visible {
                        box-shadow: none;
                    }
                }
            }
        }

    }

    .download {
        background: url(../assets/img/download.svg) center center no-repeat;
        width: 28px;
        height: 28px;
        box-shadow: none;
        border: none;
    }

    p {
        border-top: 1px solid $table-bd;
        padding-top: 10px;
    }
}
@import "../assets/styles/variables.scss";

.plan-cancel {
    border-radius: 12px;
    padding: 30px;

    span {
        color: $black;

        &.current {
            color: rgba($color: $black, $alpha: .8);
            font-size: $top-nav-fs;

            .subscription {
                font-weight: $bold;
            }
        }


        &.price {
            font-size: 40px;
            font-weight: $bold;
            padding: 7px 0;

            .details {
                font-size: $top-nav-fs;
                font-weight: $bold;
            }
        }
    }

    &.tier1 {
        background: $bg-color;
    }

    &.tier2 {
        background: $primary-color;

        span {
            color: $white;

            &.current {
                color: rgba($color: $white, $alpha: .8);
            }
        }
    }

    &.tier3 {
        background: $secondary-color;
    }

    &.tier4 {
        background: $plan-blue;

        span {
            color: $white;

            &.current {
                color: rgba($color: $white, $alpha: .8);
            }
        }
    }
}
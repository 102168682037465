@import "../assets/styles/variables.scss";

.sub-category-list {
    border-bottom: 1px solid #C7C7C7;
    padding: 13px 0;

    .btn-btn-dark {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        background: $primary-color;
        border: none;
        min-width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: $white;
        }

        &:hover,
        &:focus,
        &:active {
            background: $primary-color-hover !important;
        }

        &:active {
            box-shadow: inset 0 5px 11px 0 rgba(130, 194, 3, .5),
                0 4px 15px 0 rgba(130, 194, 3, .5) !important;

        }

        &:first-child {
            margin-right: 15px;
        }

    }
}
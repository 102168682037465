@import "./variables.scss";

/* src/Heatmap.css */
.heatmap-map {
  height: 400px;
  width: 100%;

  &.register {
    border-radius: 8px 0 0 8px;
  }
}

.legend-item {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  margin-left: 15px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.gm-style-iw button[title="Close"] {
  // display: none !important;
  background: url(../img/map-dtls-close.svg)0 0 no-repeat !important;
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  width: 16px !important;
  height: 16px !important;

  span {
    display: none !important;
  }
}

.gm-style {
  .gm-style-iw-c {
    padding-right: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    &:focus-visible{
      outline: none!important;
    }
  }

  .gm-style-iw-d {
    overflow: auto !important;
    margin-top: 10px !important;

    h6 {
      color: $black !important;
      font-size: $link-fs !important;
      margin-bottom: 5px !important;
      font-weight: $regular !important;
    }

    span {
      font-weight: $bold !important;
    }

    button {
      background: $primary-color !important;
      border-color: $primary-color !important;
      padding: 0 15px !important;
      border-radius: 15px !important;
      font-size: $link-fs !important;
      color: $white !important;
      height: 30px !important;
      border: none !important;
      margin-top: 5px !important;

      &:active {
        background: $primary-color-hover !important;
        border-color: $primary-color-hover !important;
      }

      &:focus-visible {
        box-shadow: none !important;
      }

    }
  }


  .gm-style-iw-t {
    margin-bottom: -10px;
  }
}
@import "../assets/styles/variables.scss";

.offcanvas-body{    
    padding: 30px;
    background: $bg-color;
    .top-nav{
        display: flex;
        flex-direction: column;
        a{
            padding-left: 0;
            margin-bottom: 30px;
            span{         
                width: fit-content;                                
                left: 11px;
                top: 3px;
            }
        }
    }
}
.menu{
    width: 36px;
    height: 36px;
    @include input-radius;
    background: $primary-color;
    border: none;
    display: flex;
    justify-content: center;    
    align-items: center;
    svg{
        fill: $white;      
    }
    @media (min-width:1200px) {
        display: none;
    }
    @media (max-width:576px) {
        position: absolute;
        top: 30px;
        right: 12px;
    }
}
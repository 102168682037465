@import "../assets/styles/variables.scss";

.otp-wrap {

    padding-bottom: 10px;
    margin-bottom: 35px;

    &:first-of-type {
        border-bottom: 1px solid $table-bd;
    }

    .btn-primary {
        height: 48px !important;
        font-size: $form-label-fs !important;
        font-weight: $semi-bold !important;
        min-width: 114px !important;
    }

    .btn {
        &:disabled {
            background: $action-bd-color;
            border-color: $action-bd-color;
            color: $label-color;
        }
    }

    .input-group {
        label {
            display: none;
        }
    }

    .selector-wrap {
        .icon-wrap {
            width: 48px;
            height: 48px;
            border-radius: 17px;
            background: #CDD4D9;

            svg {
                stroke: $black;
            }
        }

        .radio-wrap {
            padding-left: 20px;

            @media (max-width:576px) {
                padding-left: 10px;
            }

            span {
                @include form-label;
                margin-bottom: 7px;
                display: block;

                label {
                    &.mandatory {
                        color: $error-red;
                        padding-left: 2px
                    }

                    &.optional {
                        font-size: $link-fs;
                        color: #A6A6A6;
                        padding-left: 2px
                    }
                }
            }

            .form-check {
                .form-check-input {
                    margin-right: 10px;
                }

                .form-check-label {
                    font-size: $form-input-fs;
                    color: $black;
                }
            }
        }
    }

    .otp-input {
        @media (max-width:992px) {
            padding: 20px 0;
        }

        input {
            width: 49px !important;
            height: 49px !important;
            margin: 0 6px !important;
            border: 1px solid #DFE1E3;
            @include b-radius;
            font-size: 16px;

            &:focus {
                border: 1px solid $primary-color !important;
            }

            &:focus-visible {
                border: 1px solid $primary-color !important;
                outline: none;
            }
        }

        small {
            color: $error-red;
            padding-left: 6px;
        }
    }
}
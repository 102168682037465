@import "../assets/styles/variables.scss";

.top-nav {
    @media (max-width:1199px) {
        display: none;
    }

    a {
        color: $label-color;
        font-weight: $semi-bold;
        font-size: $top-nav-fs;
        padding:0 25px;
        &:last-child{
            padding-right: 0;
        }

        svg {
            stroke: $label-color;
            transition: 0.5s;
        }

        &:hover {
            text-decoration: none;
            color: $primary-color-hover;

            svg {
                stroke: $primary-color-hover;

            }
        }

        &.active {
            color: $primary-color;

            svg {
                stroke: $primary-color;
            }
        }

        span {
            min-width: 22px;
            height: 20px;
            padding: 3px 5px 0;
            text-align: center;
            border-radius: 10px;
            color: $white;
            background: $primary-color;
            top: -6px;
            right: -12px;
            font-size: 10px;            
            font-weight: $bold;           
            
        }
    }
}
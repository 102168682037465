@import "../assets/styles/variables.scss";

.productsimgs{
    border-radius: 12px;
    background: $bg-color;
    padding: 20px;
    margin-bottom: 20px;
    .img-wrap{        
        img{
            width: 100%;
            object-fit: cover;
            border-radius: 12px;
            height: 145px;
        }   
    }
    h4{
        font-size: $form-input-fs;
        font-weight: $extra-bold;
        margin: 20px 0 10px 0;
    }
}
@import "../assets/styles/variables.scss";

.bus-details {
    margin-bottom: 30px;

    .icon-wrap {
        background: $bg-color;
        width: 40px;
        height: 40px;
        border-radius: 12px;

        svg {
            stroke: $primary-color;
        }
    }

    .details-wrap {
        padding-left: 17px;
        line-height: 15px;
        width: calc(100% - 40px);
        word-break: break-all;

        span {
            font-size: $link-fs;
            color: $label-color;
        }

        p {
            margin: 0;
            font-weight: $semi-bold;
            line-height: 24px;

            &.verified {
                svg {
                    margin-left: 8px;
                }
            }
        }
    }
}
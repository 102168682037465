@import "../assets/styles/variables.scss";

.notify-wrap {
    background: $white;
    padding: 30px;
    @include b-radius;
    margin-top: 20px;

    @media (max-width:576px) {
        flex-direction: column;
    }

    .btn-primary {
        height: 50px !important;
        min-width: 90px !important;
        font-size: $primary-fs !important;

        @media (max-width:576px) {
            margin-top: 15px;
        }
    }

    .notify-left {
        span {
            &.message {
                font-size: $form-input-fs;
            }
        }

        .user-dtls {
            margin-top: 10px;

            .user-photo {
                width: 34px;
                height: 34px;
                border-radius: 17px;

                img {
                    width: 100%;
                    border-radius: 17px;
                    height: 100%;
                    object-fit: cover;
                }
            }

            span {
                &.user-name {
                    padding-left: 10px;
                    font-weight: $bold;
                    width: 200px;
                    overflow: hidden;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &.user-date {
                    padding-left: 10px;
                    font-size: 10px
                }
            }
        }
    }
}
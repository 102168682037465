@import "../assets/styles/variables.scss";

.message-wrap {
    background: $white;
    padding: 30px;
    position: relative;
    @include modal-radius;
    margin-bottom: 8px;

    &:first-child {
        margin-top: 40px;
    }

    @media (max-width:576px) {
        padding: 20px;
    }

    &::before {
        width: 18px;
        height: 18px;
        background: $primary-color;
        display: block;
        content: '';
        border-radius: 9px;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .profile-dtls {
        .prof-avatar {
            width: 50px;
            height: 50px;
            border-radius: 25px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 25px;
            }
        }

        span {
            font-size: 16px;
            font-weight: $bold;
            padding-left: 14px;
        }
    }

    .message-inner {
        padding-left: 64px;

        @media (max-width:576px) {
            padding-left: 0;
        }

        p {
            margin-top: 10px;
        }

        .message-selector {
            background: #F1F1F1;
            padding: 15px 15px 0 15px;
            @include b-radius;

            @media (max-width:576px) {
                text-align: center;
            }

            span {
                background: $white;
                min-width: 140px;
                display: inline-block;
                text-align: center;
                border: 1px solid $readonly;
                border-radius: 29px;
                font-size: $form-input-fs;
                font-weight: $medium;
                padding: 12px 40px;
                margin: 0 15px 15px 0;
                cursor: pointer;
                height: fit-content;

                &.active {
                    border: 1px solid $primary-color;
                    font-weight: $semi-bold;
                }

                @media (max-width:576px) {
                    margin-bottom: 15px;
                    margin-right: 0;
                }
            }

            .btn {
                height: 48px;
                float: right;
                min-width: 105px;
                font-size: 16px;
                font-weight: $medium;
                margin-bottom: 15px;


                @media (max-width:992px) {
                    float: left;
                    width: 100%;
                }
            }
        }
    }

    &.replied {
        opacity: .6;

        &::before {
            display: none;
        }

        &::after {
            content: url(../assets/img/replied.svg);
            position: absolute;
            right: 15px;
            top: 15px;
        }

        .message-inner {
            .message-selector {
                span {
                    cursor: inherit;

                    &.active {
                        background-image: url(../assets/img/message-selector.svg);
                        background-repeat: no-repeat;
                        background-position: 18px 20px;
                    }
                }

                .btn {
                    display: none;
                }
            }
        }
    }
}
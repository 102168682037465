@import "../assets/styles/variables.scss";

.search-input {

    .form-control {        
        @include form-input();
        height: 50px;
        border-radius: 25px !important;
        padding-right: 80px;
        padding-left: 20px;

        &:focus,
        &:active {
            border: 1px solid $primary-color;
        }
    }

    .clear-search {
        background: none;
        border: none;
        right: 50px;
        top: 11px;

        svg {
            fill: $readonly;

            &:hover,
            &:focus,
            &:active {
                fill: $primary-color;
            }
        }
    }

    .search {
        background: $primary-color;
        border: none;
        width: 41px;
        height: 41px;
        border-radius: 20px;
        right: 5px;
        top: 5px;

        svg {
            stroke: $white;
        }

        &:active {
            background: $primary-color-hover !important;
            border-color: $primary-color-hover;
            box-shadow: inset 0 5px 11px 0 rgba(130, 194, 3, .5),
                0 4px 15px 0 rgba(130, 194, 3, .5) !important;
        }

        &:hover,
        &:focus {
            background: $primary-color;
        }

        &:focus-visible {
            box-shadow: none;
        }
    }
    .form-text {
        &.error {
            color: $error-red;
        }
    }

    @media(max-width: 576px) {
        margin-bottom: 15px;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}
@import "../assets/styles/variables.scss";

.input-group {
    margin-bottom: 20px;

    div {
        width: 100%;

        .form-label {
            @include form-label;
            margin-bottom: 6px;

            span {
                &.mandatory {
                    color: $error-red;
                    padding-left: 2px
                }

                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
            &:empty{
                display: none;
            }
        }

        .form-control {
            @include form-input();

            &:focus,
            &:active {
                border: 1px solid $primary-color;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($black, $alpha: 0.5);
            }
        }

        .form-text {
            &.error {
                color: $error-red;
            }
        }
    }
}
.profix-input{
.input-prefix{
    position: absolute;
    top: 38.5px;
    left: 10px;
    z-index: 1;
}
input{
    padding-left: 25px;
}
}
@import "../assets/styles/variables.scss";

.cut-select{
    min-width: 300px;
    .filter {
        &__control {
            height: 48px;
            width: 100%;            
            @include form-input();
            border-radius: 25px;
            box-shadow: none;

            &:hover {
                border-color: $input-border;
            }
        }

        &__control--is-focused {
            border-color: $primary-color;
        }

        &__indicator-separator {
            display: none;
        }

        &__value-container {
            padding-left: 20px;
        }

        &__menu {
            margin: 1px 0;
            border-radius: 10px;
            padding: 7px 0;
        }

        &__option {            
            color: $black;

            &:hover {
                background: $bg-color;
            }
        }
    }
}
@import "../assets/styles/variables.scss";

.web-link {
    background: #FCFCFC;
    border: 1px solid $input-border;
    padding: 35px 25px;
    border-radius: 0 4px 4px 4px;
    margin: 60px 0 30px;

    .link-box {
        background: $primary-color;
        width: 43px;
        height: 30px;
        border-radius: 4px 4px 0 0;
        top: -31px;
        left: -1px;

        svg {
            stroke: $white;
        }
    }

    .input-group {
        margin-bottom: 0px;

        div {
            width: 100%;

            .form-label {
                @include form-label;
                margin-bottom: 6px;
            }

            .form-control {
                @include form-input();

                &:focus,
                &:active {
                    border: 1px solid $primary-color;
                }

                &:read-only {
                    border: 1px solid $readonly;
                    color: rgba($black, $alpha: 0.5);
                }
            }

            .form-text {
                &.error {
                    color: $error-red;
                }
            }
        }
    }
}